<script>
import checkoutMixin from '../checkout-mixin'
export default {
	mixins: [checkoutMixin],
	props: {
		value: [Array],
	},
	data() {
		return {
			giftCardCodes: this.value,
			maxCodes: 6,
		}
	},
	watch: {
		giftCardCodes() {
			this.$emit('input', this.giftCardCodes)
		},
		value() {
			this.giftCardCodes = this.value
		},
	},
	methods: {
		addCode() {
			if (this.giftCardCodes.length < this.maxCodes) {
				this.giftCardCodes = [...this.giftCardCodes, '']
			}
		},
		removeCode(i) {
			if (this.giftCardCodes.length > 1) {
				this.giftCardCodes.splice(i, 1)
			}
		},
	},
	// mounted() {
	// 	const { couponCode: queryCoupon } = this.checkout.initQuery || this.$route.query
	// 	if (!this.value.couponCode && queryCoupon) {
	// 		this.couponCode = queryCoupon
	// 	}
	// },
}
</script>

<template>
	<div>
		<div class="d-flex align-center">
			<v-icon class="mr-2">mdi-wallet-giftcard</v-icon>
			<b>Ingresa tu Gift Card</b>
		</div>
		<div class="pt-4 d-flex align-center" v-for="(x, i) of giftCardCodes" :key="i">
			<TextField
				v-model="giftCardCodes[i]"
				:validator-key="`giftCardCodes.${i}`"
				:label="`Código de Gift Card ${i ? `#${i + 1}` : ''}`"
				placeholder="Ingresa el código de tu Gift Card"
				outlined
				dense
				class="w100 mr-4"
			>
				<template #append>
					<v-icon @click="removeCode(i)" color="error" v-if="giftCardCodes.length > 1">
						mdi-delete-outline
					</v-icon>
				</template>
				<template #append-outer>
					<v-btn
						icon
						text
						@click="addCode()"
						:disabled="i == maxCodes - 1"
						:style="{ visibility: i < giftCardCodes.length - 1 ? 'hidden' : null }"
						color="success"
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</template>
			</TextField>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.v-text-field ::v-deep .v-input__append-outer {
	margin-top: 2px !important;
}
</style>

