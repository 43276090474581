<script>
import addressHelperMixin from '@/v-shop/mixins/address-helper-mixin.js'

const NO_STREET_NUMBER_TEXT = 'Sin número'
const NO_STREET_NUMBER_VALUE = 'S/N'

export default {
	lang: 'shop',
	name: 'AddressDialog',
	mixins: [addressHelperMixin],
	props: {
		dialog: Boolean,
		title: String,
		countriesFilter: Function,
		closeable: {
			type: Boolean,
			default: true,
		},
		hideDeliveryFields: {
			type: Boolean,
			default: false,
		},
	},
	model: {
		prop: 'dialog',
		event: 'updateDialog',
	},
	data() {
		return {
			loading: false,
			loadingActions: false,
			loadingZipcode: false,
			address: {},
			validation: {},
			selectedAddress: null,
			addresses: [],
			addressForm: false,
			country: null,
			stateLoading: null,
			geoCountryId: null,
			hasNoStreetNumber: false,
			streetNumberText: null,
		}
	},
	watch: {
		dialog(value) {
			if (value) this.loadAddresses()
		},
		country(value) {
			if (value) this.findState()
		},
		addressForm(value) {
			if (value) {
				this.hasNoStreetNumber = this.address.streetNumber == NO_STREET_NUMBER_VALUE
				this.streetNumberText = this.hasNoStreetNumber
					? NO_STREET_NUMBER_TEXT
					: this.address.streetNumber || ''
			}
		},
	},
	computed: {
		dialogWidth() {
			if (this.loading) return 500
			if (this.addressForm) return 800
			return 700
		},
		dialogTitle() {
			if (this.addressForm) {
				return this.address.id ? this.$lang('Modificar dirección') : this.$lang('Crear nueva dirección')
			} else {
				return this.title || this.$lang('Selecciona la dirección')
			}
		},
		fixedState() {
			return this.country?.zipcodeValidation == 1
		},
	},
	methods: {
		accept() {
			if (this.addressForm) {
				this.saveAddress()
			} else {
				this.$emit('addressSelected', this.selectedAddress)
				this.closeDialog()
			}
		},
		cancel() {
			if (this.addressForm && this.addresses.length) {
				this.addressForm = false
			} else {
				this.closeDialog()
			}
		},
		closeDialog() {
			this.$emit('updateDialog', false)
		},
		selectAddress(address) {
			this.$emit('addressSelected', address)
			this.closeDialog()
		},
		createAddress() {
			this.addressForm = true
			this.address = {
				countryId: this.geoCountryId,
			}
		},
		editAddress(address) {
			this.address = {
				id: address.id,
				userId: address.userId,
				countryId: address.zipcode.state.country.id,
				zipcode: address.zipcode.code,
				state: address.zipcode.state.name,
				city: address.city,
				street: address.street,
				streetNumber: address.streetNumber,
				floor: address.floor,
				apartment: address.apartment,
				intersection1: address.intersection1,
				intersection2: address.intersection2,
				comment: address.comment,
			}
			this.addressForm = true
		},
		async loadAddresses(withLoader = true) {
			await this.$shopApi.get({
				url: '/user/addresses',
				loading: (v) => (this.loading = withLoader ? v : false),
				onSuccess: ({ data }) => {
					let addresses = data.addresses || []
					if (this.countriesFilter) {
						addresses = addresses.filter((address) =>
							this.countriesFilter(address.zipcode.state.country)
						)
					}
					this.addresses = addresses
					this.geoCountryId = data.geoCountryId
				},
			})
			if (this.addresses.length) {
				this.selectedAddress = this.addresses[0]
				this.addressForm = false
			} else {
				this.createAddress()
			}
		},
		saveAddress() {
			let isNew = !this.address.id
			let options = {
				data: {
					...this.address,
					streetNumber: this.hasNoStreetNumber ? NO_STREET_NUMBER_VALUE : this.address.streetNumber,
				},
				loading: (v) => (this.loadingActions = v),
				onValidation: ({ validation }) => (this.validation = validation),
				onSuccess: async ({ data, options }) => {
					this.addressForm = false
					await this.loadAddresses()
					this.selectedAddress = this.addresses.find(({ id }) => id == data.address.id)
					if (isNew) this.accept()
				},
			}
			if (isNew) this.$shopApi.post('/user/address', options)
			else this.$shopApi.put(`/user/address/${this.address.id}`, options)
		},
		findState() {
			this.$set(this.validation, 'address.zipcode', null)
			if (!this.fixedState || !this.address.countryId || !this.address.zipcode) return
			this.$shopApi.get(`/location/state-from-zipcode`, {
				query: {
					countryId: this.address.countryId,
					zipcode: this.address.zipcode,
				},
				loading: (v) => (this.stateLoading = v),
				done: ({ success, data }) => {
					if (success) {
						this.address.state = data.state.name
					} else {
						this.address.state = null
						this.$set(this.validation, 'address.zipcode', this.$lang('El código postal es inválido'))
					}
				},
			})
		},
		setZipcodeId(value) {
			this.$set(this.address, 'zipcodeId', value)
		},
		streetNumberFilter(text) {
			this.address.streetNumber = text.replace(/[^0-9]/g, '')
			return this.address.streetNumber
		},
		streetNumberFilterDisabled(text) {
			return text == NO_STREET_NUMBER_TEXT
		},
		toggleNoStreetNumber() {
			this.hasNoStreetNumber = !this.hasNoStreetNumber
			this.streetNumberText = this.hasNoStreetNumber ? NO_STREET_NUMBER_TEXT : this.address.streetNumber
		},
	},
	mounted() {
		if (this.dialog) this.loadAddresses()
	},
}
</script>

<template>
	<v-dialog :value="dialog" :max-width="dialogWidth + 'px'" persistent scrollable v-bind="$attrs">
		<!-- Loading Card -->
		<v-card v-if="loading" class="py-4">
			<v-card-title>
				<v-progress-circular indeterminate size="30" class="mr-4" />
				{{ 'Cargando tus direcciones' | lang }}
			</v-card-title>
		</v-card>
		<!-- Loaded Card -->
		<v-card v-else>
			<!-- dynamic title -->
			<v-card-title>
				<div class="d-flex w100 align-center">
					<span class="font-3">{{ dialogTitle }}</span>
					<template v-if="$b.m">
						<v-spacer />
						<Button icon @click="cancel" :disabled="loadingActions">
							<v-icon>mdi-close</v-icon>
						</Button>
					</template>
				</div>
			</v-card-title>
			<!-- User Addresses selectors radios -->
			<v-card-text style="max-height: 1000px" v-if="!addressForm">
				<v-radio-group v-model="selectedAddress" class="mt-0" hide-details="auto">
					<div v-for="address of addresses" :key="address.id" class="my-2">
						<v-radio
							:value="address"
							class="align-start addressbox"
							:class="{ 'addressbox--selected': selectedAddress == address }"
							:color="selectedAddress == address ? 'success' : ''"
						>
							<template #label>
								<div>
									<span :class="{ 'font-weight-bold': selectedAddress == address }">
										{{ makeAddressLine(address, !hideDeliveryFields) }}
									</span>
									<div v-if="$b.m" class="text-right pt-3">
										<Button color="primary" small outlined block @click="editAddress(address)">
											<v-icon class="mr-1" small>mdi-pencil</v-icon>
											{{ 'Editar' | lang }}
										</Button>
									</div>
								</div>
								<template v-if="!$b.m">
									<v-spacer />
									<Button color="primary" small @click="editAddress(address)" outlined class="ml-4">
										<v-icon class="mr-1" small>mdi-pencil</v-icon>
										{{ 'Editar' | lang }}
									</Button>
								</template>
							</template>
						</v-radio>
					</div>
					<Button block outlined color="link" class="my-2" @click="createAddress()">
						<v-icon left>mdi-plus</v-icon>
						{{ $lang('Nuevo domicilio') }}
					</Button>
				</v-radio-group>
			</v-card-text>
			<!-- Address Edit/Create Form -->
			<v-card-text style="max-height: 1000px" class="pt-4" v-if="addressForm">
				<Validator :validation="validation">
					<div>
						<v-row>
							<v-col cols="12" sm="6">
								<CountrySelector
									:label="$lang('País')"
									v-model="address.countryId"
									@country-selected="country = $event"
									:countries-filter="countriesFilter"
								/>
							</v-col>
							<v-col cols="12" sm="6">
								<TextField
									ref="zipcodeTextField"
									:label="$lang('Código Postal')"
									v-model="address.zipcode"
									:loading="loadingZipcode"
									:style="{ width: $b.td ? '200px' : '' }"
									@change="findState()"
								/>
							</v-col>
							<v-col cols="12" sm="6">
								<TextField
									:label="$lang('Provincia / Estado')"
									v-model="address.state"
									:disabled="fixedState && !address.state"
									:readonly="fixedState"
									:loading="stateLoading"
									:hint="
										fixedState && !address.state
											? $lang('Ingresa el código postal para autocompletar este campo')
											: null
									"
									:persistent-hint="!!fixedState && !address.state"
								/>
							</v-col>
							<v-col cols="12" sm="6">
								<TextField :label="$lang('Ciudad / Localidad')" v-model="address.city" />
							</v-col>
							<v-col cols="12" md="4">
								<TextField :label="$lang('Calle')" v-model="address.street" />
							</v-col>
							<v-col cols="12" sm="4" md="4">
								<MaskedTextField
									type="tel"
									:label="$lang('Altura')"
									v-model="streetNumberText"
									:mask="(text) => streetNumberFilter(text)"
									:mask-disabled="(text) => streetNumberFilterDisabled(text)"
									:disabled="hasNoStreetNumber"
									validator-key="address.streetNumber"
								>
									<template #append>
										<v-btn
											:outlined="!hasNoStreetNumber"
											:color="hasNoStreetNumber ? 'warning' : 'grey darken-3'"
											@click="toggleNoStreetNumber()"
											small
											style="min-height: 20px; min-width: 1px; padding: 0 8px"
										>
											S/N
										</v-btn>
									</template>
								</MaskedTextField>
							</v-col>
							<v-col cols="6" sm="4" md="2">
								<TextField
									:label="$lang('Piso')"
									v-model="address.floor"
									:hint="$lang('Opcional')"
									persistent-hint
								/>
							</v-col>
							<v-col cols="6" sm="4" md="2">
								<TextField
									:label="$lang('Departamento')"
									v-model="address.apartment"
									:hint="$lang('Opcional')"
									persistent-hint
								/>
							</v-col>
							<v-col cols="6" md="3" v-if="!hideDeliveryFields">
								<TextField
									:label="$lang('Entrecalle 1')"
									v-model="address.intersection1"
									:hint="$lang('Opcional')"
									persistent-hint
								/>
							</v-col>
							<v-col cols="6" md="3" v-if="!hideDeliveryFields">
								<TextField
									:label="$lang('Entrecalle 2')"
									v-model="address.intersection2"
									:hint="$lang('Opcional')"
									persistent-hint
								/>
							</v-col>
							<v-col cols="12" md="6" v-if="!hideDeliveryFields">
								<TextField
									:label="$lang('Comentarios / Instrucciones')"
									v-model="address.comment"
									:hint="$lang('Opcional')"
									persistent-hint
								/>
							</v-col>
						</v-row>
					</div>
				</Validator>
			</v-card-text>
			<!-- Bottom Dynamic Actions -->
			<v-card-actions>
				<v-spacer></v-spacer>
				<Button text @click="cancel" :disabled="loadingActions" v-if="!$b.m && closeable">
					{{ 'Cancelar' | lang }}
				</Button>
				<Button color="success" @click="accept" :loading="loadingActions">
					{{ addressForm ? $lang('Aceptar') : $lang('Seleccionar') }}
				</Button>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style scoped>
.v-dialog__content ::v-deep .v-dialog {
	margin: 12px;
}
.v-text-field {
	margin-top: 0;
	padding-top: 6px;
}
.addressbox {
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 10px;
}
.addressbox--selected {
	border: 2px solid #444;
	margin: -1px;
}
.v-card__actions {
	border-top: 1px solid #ddd;
}
</style>
