<script>
import addressHelperMixin from '@/v-shop/mixins/address-helper-mixin.js'
import checkoutMixin from '../checkout-mixin'

export default {
	lang: 'shop',
	mixins: [checkoutMixin, addressHelperMixin],
	data() {
		return {
			address: null,
			receiver: {
				firstname: null,
				lastname: null,
				idNumber: null,
			},
			addressDialog: false,
			selection: null,
			loadingOptions: false,
			sellersOptions: null,
			countriesFilter: null,
		}
	},
	computed: {
		canSubmit() {
			let allSelected = this.sellersOptions?.every(({ seller }) => !!this.selection[seller.id])
			return !!(allSelected && this.receiver.firstname && this.receiver.lastname && this.receiver.idNumber)
		},
		allSelectionsArePickupPoint() {
			return Object.values(this.selection || {}).every((x) => x && x.methodKey == 'PickupPoint')
		},
	},
	methods: {
		async onAddressSelected(address) {
			this.address = address
			this.calculateSellersOptions()
		},
		async calculateSellersOptions() {
			if (!this.address) return

			const response = await this.checkout.reqWrapper(({ data, done }) => {
				data.address = this.address
				return this.$shopApi.post({
					url: '/checkout/delivery/calculate',
					data,
					loading: (v) => (this.loadingOptions = v),
					done,
				})
			})

			if (response.success) {
				this.sellersOptions = response.data.sellersOptions
			}
		},
		onItemsRemoved() {
			this.checkout.disallowCheckerMessages = true
			this.calculateSellersOptions()
		},
		async submit() {
			await this.checkout.stepSubmit({
				clientAddress: this.address,
				selection: this.selection,
				receiver: this.receiver,
			})
		},
	},
	mounted() {
		let { selectedAddress, availableCountriesIds } = this.checkout.stepViewData || {}
		if (selectedAddress) {
			this.onAddressSelected(selectedAddress)
		} else {
			this.addressDialog = true
		}
		if (availableCountriesIds?.length) {
			this.countriesFilter = (country) => availableCountriesIds.includes(country.id)
		}

		let { receiver, selection } = this.checkout.stepsData?.delivery?.payload || {}
		this.selection = { ...(selection || {}) }
		if (receiver) {
			this.receiver = receiver
		} else {
			let { user } = this.checkout.order
			this.receiver = {
				firstname: user.firstname,
				lastname: user.lastname,
				idNumber: user.idNumber,
			}
		}
	},
	hooksKey: 'CheckoutStepDeliveryActive',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
}
</script>

<template>
	<div>
		<Validator :validation="checkout.validation">
			<div>
				<div class="font-weight-bold d-flex align-center">
					<v-icon left small>mdi-map-marker</v-icon>
					<div>{{ 'Tu domicilio' | lang }}</div>
				</div>
				<div v-if="!address">
					<Button color="link" outlined small @click="addressDialog = true" class="mt-3">
						<v-icon left>mdi-chevron-right</v-icon>
						{{ 'Ingresá tu domicilio' | lang }}
					</Button>
				</div>
				<div v-else>
					<div class="d-flex flex-column flex-sm-row align-sm-center justify-space-between">
						<div class="pr-4">{{ makeAddressLine(address) }}</div>
						<Button color="link" @click="addressDialog = true" :block="$b.m" class="mt-2 mt-sm-0">
							<v-icon small left>mdi-pencil</v-icon>
							{{ 'Modificá tu domicilio' | lang }}
						</Button>
					</div>

					<div v-if="loadingOptions" class="pt-7 d-flex align-center">
						<v-progress-circular indeterminate width="2" size="30" />
						<div class="ml-3">{{ 'Cargando opciones de envío' | lang }}</div>
					</div>

					<div v-if="!loadingOptions" class="py-6">
						<div v-for="{ seller, items, options } of sellersOptions" :key="seller.id">
							<CheckoutDeliverySellerBox
								v-model="selection[seller.id]"
								v-bind="{ seller, items, options, singleSeller: sellersOptions.length == 1, address }"
								@items-removed="onItemsRemoved()"
							/>
						</div>
					</div>

					<div class="pb-6" v-if="!loadingOptions">
						<div class="font-weight-bold mb-2">
							<v-icon left>mdi-handshake-outline</v-icon>
							<span v-if="allSelectionsArePickupPoint">{{
								'¿Quién va a retirar el producto?' | lang
							}}</span>
							<span v-else>{{ '¿Quién va a recibir el producto?' | lang }}</span>
						</div>
						<v-row>
							<v-col cols="6">
								<TextField v-model="receiver.firstname" label="Nombre" outlined :dense="$b.m" />
							</v-col>
							<v-col cols="6">
								<TextField v-model="receiver.lastname" label="Apellido" outlined :dense="$b.m" />
							</v-col>
							<v-col cols="12">
								<TextField
									v-model="receiver.idNumber"
									label="Nro. de documento"
									outlined
									:dense="$b.m"
								/>
							</v-col>
						</v-row>
						<hook zone="below-receiver-form" />
					</div>
					<div class="d-flex justify-end" v-if="!loadingOptions">
						<Button
							:disabled="!canSubmit"
							@click="submit()"
							color="success"
							:block="$b.xsOnly"
							:large="$b.xsOnly"
						>
							Siguiente
						</Button>
					</div>
				</div>
			</div>
		</Validator>
		<AddressDialog
			v-model="addressDialog"
			@addressSelected="onAddressSelected"
			:title="$lang('Seleccioná tu domicilio')"
			:countries-filter="countriesFilter"
		/>
	</div>
</template>

