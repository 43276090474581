<script>
import makeSummary from '#/shop-order/helpers/make-summary'
import checkoutMixin from '../checkout-mixin'

export default {
	mixins: [checkoutMixin],
	computed: {
		summary() {
			return makeSummary(this.checkout.order)
		},
		joinedItems() {
			return this.summary.getJoinedItems()
		},
	},
	hooksKey: 'CheckoutSummary',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
}
</script>

<template>
	<div class="checkout-summary" :class="{ 'checkout-summary--desktop': $b.d }">
		<v-card
			class="pa-1 d-flex flex-column elevation-1"
			:class="[$b.mt ? 'rounded-0' : 'rounded-lg']"
			v-if="checkout.order"
		>
			<hook zone="above-items" />
			<div class="checkout-summary__items px-2">
				<template v-for="(jItem, i) of joinedItems">
					<OrderSummary-Item :order="checkout.order" :joined-item="jItem" :key="`i${i}`" />
					<v-divider v-if="i < joinedItems.length - 1" :key="`d${i}`" />
				</template>
			</div>
			<hook zone="below-items" />
			<OrderSummary-Totals :order="checkout.order" :summary="summary" class="px-2" />
			<hook zone="below-totals" />
		</v-card>
		<!-- <v-card class="pa-5 rounded-lg elevation-1" style="height: auto" v-else>
			<v-skeleton-loader loading type="heading" class="mb-6" />
			<v-skeleton-loader loading type="list-item-avatar@6" />
		</v-card> -->
	</div>
</template>

<style lang="scss" scoped>
.checkout-summary {
	/*& > .v-card {
		height: calc(100vh - 44px);
	}

	&--desktop {
		position: sticky;
		top: 20px;
		& > .v-card {
			height: calc(100vh - 100px);
		}
	}

	&__items {
		height: 100%;
		overflow: auto;
	}*/
}
::-webkit-scrollbar {
	width: 6px;
	height: 8px;
	padding: 20px;
}

::-webkit-scrollbar-track {
	background: #fff;
	/* background: transparent; */
}

::-webkit-scrollbar-thumb {
	background: #ddd;
	border-radius: 10px;
	cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
	background: #aaa;
}
</style>

